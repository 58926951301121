import React, { useEffect, useMemo, useState, useImperativeHandle } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classnames from 'classnames';
import { camelCase, isEmpty, isString } from 'lodash';
import { Spinner, Pagination, SelectInput } from '@webfx/core-web';
import { useLocalStorage } from '@webfx/web-hooks';
import { Icon } from '@webfx/web-ui';
import { useRowSelectionContext } from '@webfx/marketingcloud-web/src/ui/components/TableRowSelect/RowSelectionProvider';
import ManageColumns from './ManageColumns';
import { buildColumn, buildDefaultColumn } from './table-utils';

import styles from './Table.module.css';

const defaultColumn = buildDefaultColumn();

/**
 * An abstracted table component wrapper for https://tanstack.com/table/v8
 * @param {object} props
 * @param {string} props.name - The name of the table, used for local storage
 * @param {Array} props.data - The data to be displayed in the table
 * @param {Array} props.columns - The columns to be displayed in the table
 * @param {boolean} props.isLoading - Whether or not the table is loading
 * @param {React.Element} props.loadingElement - The React Element to be displayed when the table is loading
 * @param {React.Element} props.titleElement - The component to be displayed above the table
 * @param {React.Element} props.noDataElement - The React Element to be displayed when there is no data
 * @param {boolean} props.manageColumns - Whether or not to display the manage columns button
 * @param {string} props.className - The class name to be applied to the table
 * @param {boolean} props.stickyFirstColumn - Whether or not the first column should be sticky
 * @param {Function} props.updateData - The function to be called when the table data is updated
 * @param {boolean} props.expandAllRows - Whether or not all rows should be expanded
 *
 * Pagination options for this table component is set up the same as @webfx/core-web/src/components/Table
 * @param {boolean} props.paginate - Whether or not to display pagination
 * @param {boolean} props.manualPagination - Whether or not to use manual pagination
 * @param {number} props.totalEntries - The total number of entries
 * @param {Function} props.onPageIndexChange - The function to be called when the page index is changed
 * @param {Function} props.onPageSizeChange - The function to be called when the page size is changed
 * @param {boolean} props.showPaginationStatus - Whether or not to display the pagination status
 * @param {number} props.defaultPageIndex - The default page index
 * @param {number} props.defaultPageSize - The default page size
 * @param {number} props.defaultPageCount - The default page count
 * @param {number} props.currentPage - Index of current page
 * @param {string} props.paginationClassName - The class name to be applied to the pagination
 *
 * Sorting options for table
 * @param {boolean} props.externalSort - enable server-side sorting to the table, disabled by default
 * @param {Function} props.onSort - the function to be called when the table is sorted when server-side pagination is enabled
 * @param props.resetSort
 * @param props.clearSorting
 * @param props.enableRowSelection
 * @param props.innerRef
 * @param props.onRowSelectionChangeCb
 * @returns {React.Component} - The table component
 */
const Table = ({
  name,
  data,
  columns,
  isLoading,
  loadingElement = null,
  titleElement = null,
  noDataElement = null,
  manageColumns = true,
  className = '',
  stickyFirstColumn = false,
  updateData = () => {},
  expandAllRows = false,
  // Pagination
  paginate,
  manualPagination,
  totalEntries,
  onPageIndexChange,
  onPageSizeChange,
  showPaginationStatus,
  defaultPageIndex = 0,
  defaultPageSize = 20,
  defaultPageCount,
  currentPage,
  paginationClassName,
  //External Sort is disabled by default
  externalSort = false,
  onSort,
  clearSorting = false,
  // multirow select, don't use this directly, use SelectableRowTable component
  enableRowSelection = false,
  innerRef,
  onRowSelectionChangeCb,
  ...passThroughProps
}) => {
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `mcfx-${name}:column-visibility`,
    null
  );
  const [expanded, setExpanded] = useState({});
  const [sorting, setSorting] = useState([]);
  useEffect(() => {
    if (onSort) {
      onSort(sorting);
    }
  }, [sorting]);

  const builtColumns = columns.map(buildColumn);
  const [rowSelection, setRowSelection] = React.useState({});

  const tableState = useMemo(() => {
    const state = {
      columnVisibility,
      expanded,
      sorting,
      rowSelection,
    };

    if (manualPagination) {
      state.pagination = {
        pageIndex: defaultPageIndex,
        pageSize: defaultPageSize,
      };
    }

    return state;
  }, [
    columnVisibility,
    defaultPageIndex,
    defaultPageSize,
    expanded,
    manualPagination,
    sorting,
    rowSelection,
  ]);

  const table = useReactTable({
    data: data ?? {},
    columns: builtColumns,
    defaultColumn,
    pageCount: manualPagination ? defaultPageCount ?? 1 : defaultPageCount,
    initialState: {
      columnVisibility,
      expanded,
      pagination: {
        pageIndex: defaultPageIndex,
        pageSize: defaultPageSize,
      },
      sorting,
    },
    state: tableState,
    onSortingChange: setSorting,
    manualSorting: externalSort,
    manualPagination,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    meta: {
      updateData,
    },
    getPaginationRowModel: paginate && !manualPagination ? getPaginationRowModel() : null,
    getSortedRowModel: externalSort ? null : getSortedRowModel(),
    // multi-table de-selection
    enableRowSelection,
    onRowSelectionChange: (state) => {
      setRowSelection(state);
      if (onRowSelectionChangeCb) {
        onRowSelectionChangeCb(rowSelection);
      }
    },
  });

  const {
    setPageIndex: gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    getIsSomeRowsSelected,
    getIsAllRowsSelected,
  } = table;

  const canExpand = table.getRowModel().rows.some((row) => row.getCanExpand());

  if (enableRowSelection && (!innerRef || !onRowSelectionChangeCb)) {
    // Not all of them are defined, throw an error
    throw new Error(
      'To enable enableRowSelection, innerRef and onRowSelectionChangeCb must be defined with SelectableRowTable component.'
    );
  }

  // table ref
  useImperativeHandle(
    innerRef,
    () => {
      return {
        toggleAllRowsSelected,
        getIsSomeRowsSelected,
        getIsAllRowsSelected,
      };
    },
    []
  );

  const rowSelectionContext = useRowSelectionContext();

  let updateStateByKey;
  let tableName;
  let setTable;
  if (rowSelectionContext) {
    ({ updateStateByKey, table: tableName, setTable } = rowSelectionContext);
  }

  useEffect(() => {
    if (enableRowSelection) {
      const data = table.getSelectedRowModel().flatRows.map((row) => row.original);

      if (tableName !== name) {
        setTable(name);
      }
      updateStateByKey(name, data);
    }
  }, [rowSelection]);

  const pageIndex = manualPagination ? currentPage : table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const pageCount = table.getPageCount();

  const paginationStatus = useMemo(() => {
    if (!showPaginationStatus || !data?.length) {
      return;
    }

    const countTotal = totalEntries || data?.length || 0;

    let countEnd = (pageIndex + 1) * pageSize;
    if (countEnd > countTotal) {
      countEnd = countTotal;
    }
    const countStart = pageIndex === 0 ? 1 : pageIndex * pageSize + 1;

    return (
      <div className="PW__entries-container" data-fx-name="paginationInfo">
        Showing {!countTotal ? 'entries' : null} {countStart} to {countEnd}{' '}
        {countTotal ? `of ${countTotal} entries` : null}
      </div>
    );
  }, [data?.length, pageIndex, pageSize, totalEntries, showPaginationStatus]);

  const { toggleAllRowsExpanded, getIsAllRowsExpanded } = table;

  useEffect(() => {
    toggleAllRowsExpanded(expandAllRows);
  }, [getIsAllRowsExpanded, toggleAllRowsExpanded]);

  const hiddenColumns = useMemo(
    () =>
      table.getAllFlatColumns().reduce((acc, col) => {
        if (col.columnDef.hideByDefault) {
          acc[col.id] = false;
        }
        return acc;
      }, {}),
    [table]
  );
  useEffect(() => {
    // Only set default hidden columns if visibility hasn't already been customized
    if (isEmpty(columnVisibility)) {
      setColumnVisibility(hiddenColumns);
    }
  }, [hiddenColumns]);

  const hasHeaderGroupings = table.getHeaderGroups().length > 1;
  const renderFooter = table
    .getFooterGroups()
    .some((group) => group.headers?.some((header) => header.column.columnDef.footer));

  return (
    <>
      {titleElement || manageColumns ? (
        <div
          className="d-flex align-items-center p-2"
          data-fx-name={
            passThroughProps['data-fx-name']
              ? `${passThroughProps['data-fx-name']}TableManager`
              : 'tableManager'
          }
        >
          {titleElement ? titleElement : null}
          {manageColumns ? <ManageColumns table={table} /> : null}
        </div>
      ) : null}
      {!isLoading ? (
        <div
          className={classnames(styles.tableWrapper, className)}
          data-fx-name={
            passThroughProps['data-fx-name']
              ? `${passThroughProps['data-fx-name']}Table`
              : 'tableSection'
          }
        >
          <table className="w-100 font-14" data-fx-name="subRowsTable">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                const isGroupedHeader = hasHeaderGroupings && headerGroup.depth === 0;
                const headerGroupClasses = isGroupedHeader
                  ? 'bg-blue-300 text-white'
                  : 'bg-cornflower-blue text-gray-600';
                return (
                  <tr
                    key={headerGroup.id}
                    className={classnames(
                      headerGroupClasses,
                      'text-uppercase',
                      styles.header,
                      isGroupedHeader && styles.groupedHeader
                    )}
                  >
                    {headerGroup.headers.map((header, index) => {
                      const isSortable =
                        header.column.columnDef.sortable !== false && !isGroupedHeader;
                      const isSorted = header.column.getIsSorted();

                      if (clearSorting && isSorted) {
                        header.column.clearSorting();
                      }

                      const sortIcon =
                        !isSorted || isSorted === 'asc' ? 'arrow_upward' : 'arrow_downward';

                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={classnames(
                            stickyFirstColumn && index === 0 && styles.stickyCell,
                            'px-2 py-3'
                          )}
                          style={header.column.columnDef.cellStyle ?? null}
                        >
                          {!header.isPlaceholder && !header.column.columnDef.emptyHeader ? (
                            <div
                              className={classnames(
                                isSortable && styles.sortable,
                                'd-flex align-items-center'
                              )}
                              onClick={
                                isSortable ? header.column.getToggleSortingHandler() : () => {}
                              }
                              data-fx-name="tableHeader"
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {/* Add empty span with unique ID for easy Pendo tooltip additions */}
                              <span
                                id={`${name}-header-${header.id}`}
                                className="d-flex align-items-center ml-1"
                              ></span>
                              {isSortable ? (
                                <Icon
                                  title="Toggle Sort"
                                  className={classnames(
                                    styles.sortIndicator,
                                    isSorted && styles.sorting
                                  )}
                                  data-fx-name="sortIcon"
                                >
                                  {sortIcon}
                                </Icon>
                              ) : null}
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {data?.length ? (
                <>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr
                      key={row.id}
                      className={classnames(
                        index % 2 === 0 ? 'bg-white' : 'bg-table-accent-bg',
                        styles.tableRow,
                        row.depth > 0 && styles.subRow
                      )}
                      data-fx-name="rowContainer"
                    >
                      {row.getVisibleCells().map((cell, index) => {
                        const isExpandToggle = row.getCanExpand() && index === 0;
                        const dataFxName = isString(cell.column.columnDef?.header)
                          ? cell.column.columnDef.header
                          : cell.column.columnDef?.accessorKey;
                        const isTotalCell =
                          row.id === '0' && index === 0 && canExpand && cell.getValue() === 'TOTAL';
                        return (
                          <td
                            key={cell.id}
                            className={classnames(
                              'px-2 py-3',
                              index === 0 && row.depth > 0 ? `pl-${row.depth + row.depth + 3}` : '',
                              stickyFirstColumn && index === 0 && styles.stickyCell
                            )}
                            style={cell.column.columnDef.cellStyle ?? null}
                            data-fx-name={camelCase(dataFxName)}
                          >
                            <div
                              className={classnames(
                                isExpandToggle && 'cursor-pointer',
                                'd-flex align-items-center',
                                cell.column.columnDef.cellClassName
                              )}
                              onClick={isExpandToggle ? row.getToggleExpandedHandler() : () => {}}
                              data-fx-name="cellValue"
                            >
                              {isTotalCell ? (
                                <div className="d-flex align-items-center mr-auto font-12">
                                  <div
                                    className="text-uppercase mr-2 cursor-pointer text-primary-blue"
                                    onClick={() => toggleAllRowsExpanded(true)}
                                  >
                                    Expand All
                                  </div>
                                  <div
                                    className="text-uppercase cursor-pointer text-primary-blue"
                                    onClick={() => toggleAllRowsExpanded(false)}
                                  >
                                    Collapse All
                                  </div>
                                </div>
                              ) : null}
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              {isExpandToggle ? (
                                <Icon
                                  className="ml-auto mr-2 font-32 text-primary-blue"
                                  data-fx-name="expandChannel"
                                >
                                  {row.getIsExpanded() ? 'expand_less' : 'expand_more'}
                                </Icon>
                              ) : null}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={table.getAllFlatColumns().length}>
                    {noDataElement ? (
                      noDataElement
                    ) : (
                      <span className="text-center p-4" data-fx-name="noDataToShowLabel">
                        No data to show.
                      </span>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
            {renderFooter ? (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.footer, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            ) : null}
          </table>
          {paginate ? (
            <div
              className={classnames(
                'PW mt-3 mb-3 text-center row d-flex align-items-center',
                paginationClassName
              )}
              data-fx-name="paginationSection"
            >
              {showPaginationStatus ? <div className="col col-sm-3">{paginationStatus}</div> : null}
              <div className="col-sm" data-fx-name="pagination">
                <Pagination
                  onPageChange={({ selected }) => {
                    gotoPage(Number(selected));
                    if (onPageIndexChange) {
                      onPageIndexChange(Number(selected));
                    }
                  }}
                  pageCount={pageCount}
                  forcePage={pageIndex}
                  withBorder={true}
                />
              </div>
              <div className="col col-sm-3" data-fx-name="showEntries">
                <span data-fx-name="showLabel">Show</span>
                <SelectInput
                  className="PW_Select ml-2 mr-2"
                  options={[...[10, 20, 30, 40, 50, 100].map((value) => ({ label: value, value }))]}
                  field={{
                    name: 'pageSize',
                    value: pageSize,
                  }}
                  form={{
                    setFieldValue: (name, value) => {
                      setPageSize(Number(value));
                      if (onPageSizeChange) {
                        onPageSizeChange(Number(value));
                      }
                    },
                    touched: { select: true },
                  }}
                  menuPlacement="auto"
                />
                <span data-fx-name="entriesLabel">Entries</span>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <>
          {loadingElement ? (
            loadingElement
          ) : (
            <div className="d-flex justify-content-center p-4">
              <Spinner />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Table;
