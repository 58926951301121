/**
 * An abstracted Dialog component wrapper around
 * https://headlessui.com/react/dialog
 */
import classnames from 'classnames';
import { Dialog as HeadlessDialog } from '@headlessui/react';

import Dialog from './Dialog';

Dialog.Panel = ({ children, className, ...props }) => (
  <HeadlessDialog.Panel className={classnames('p-4', className)} {...props}>
    {children}
  </HeadlessDialog.Panel>
);
Dialog.Title = HeadlessDialog.Title;
Dialog.Description = HeadlessDialog.Description;

export default Dialog;
